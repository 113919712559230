@font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 100;
    src: local('Helvetica Neue UltraLight'), url('../assets/fonts/helvetica-neue-ultra-light.ttf') format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: italic;
    font-weight: 100;
    src: local('Helvetica Neue UltraLight Italic'), url('../assets/fonts/helvetica-neue-ultra-light-italic.ttf') format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 200;
    src: local('Helvetica Neue Thin'), url('../assets/fonts/helvetica-neue-thin.ttf') format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: italic;
    font-weight: 200;
    src: local('Helvetica Neue Thin Italic'), url('../assets/fonts/helvetica-neue-thin-italic.ttf') format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 300;
    src: local('Helvetica Neue Light'), url('../assets/fonts/helvetica-neue-light.ttf') format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: italic;
    font-weight: 300;
    src: local('Helvetica Neue Light Italic'), url('../assets/fonts/helvetica-neue-light-italic.ttf') format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    src: local('Helvetica Neue'), url('../assets/fonts/helvetica-neue.ttf') format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: italic;
    font-weight: 400;
    src: local('Helvetica Neue Italic'), url('../assets/fonts/helvetica-neue-italic.ttf') format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 500;
    src: local('Helvetica Neue Medium'), url('../assets/fonts/helvetica-neue-medium.ttf') format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: italic;
    font-weight: 500;
    src: local('Helvetica Neue Medium Italic'), url('../assets/fonts/helvetica-neue-medium-italic.ttf') format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 700;
    src: local('Helvetica Neue Bold'), url('../assets/fonts/helvetica-neue-bold.ttf') format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: italic;
    font-weight: 700;
    src: local('Helvetica Neue Bold Italic'), url('../assets/fonts/helvetica-neue-bold-italic.ttf') format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 800;
    src: local('Helvetica Neue Condensed Bold'), url('../assets/fonts/helvetica-neue-condensed-bold.ttf') format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 900;
    src: local('Helvetica Neue Condensed Black'), url('../assets/fonts/helvetica-neue-condensed-black.ttf') format('truetype');
    font-display: fallback;
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Helvetica Neue', sans-serif;
    font-weight: normal;
    background-color: #fff;
    color: #070B0F;
}

#root {
    scrollbar-gutter: stable;
    overflow: auto;
}
